import { activateSubscriptionApiCall, cancelMultipleSubscriptionsSessionsByIdsApiCall, cancelSessionWithAllSubscriptionsApiCall, cancelSubscriptionApiRequest, cancelSubscriptionSessionApiRequest, cancelSubscriptionsOfUserInSpecificClassApiCall, changeUserSessionSubscriptionPresenceApiCall, checkSessionsForSubscriptionNApiCall, createSessionsForSubscriptionNApiCall, createSubscriptionApiCall, createSubscriptionAsAnonymousApiCall, createSubscriptionOnPastEventApiCall, getCanceledSubscriptionsBySessionApiRequest, getSubscriptionPriceApiRequest, getSubscriptionsBySessionIdApiRequst, getSubscriptionsOfUserInSpecificClassApiCall, getSubscriptionsSessionsForMultipleCancelApiCall, validateMultipleSessionsMembershipRulesApiCall } from '@guryou/api-client-gql';
import { parseAsBool } from '@guryou/utils';
import { execute } from '../actions/actionWrapper';
import { resetState } from '../actions/resetActions';
import { Action, ActionStatus, basicReducer, buildActionType } from '../core';
export const SUBSCRIPTIONS_CREATE = {
  entity: 'SUBSCRIPTIONS',
  action: Action.CREATE
};
export const SUBSCRIPTIONS_UPDATE = {
  entity: 'SUBSCRIPTIONS',
  action: Action.UPDATE
};
export const SUBSCRIPTIONS_GET_MANY = {
  entity: 'SUBSCRIPTIONS',
  action: Action.GET_MANY
};
export const CANCELED_SUBSCRIPTIONS_GET_MANY = {
  entity: 'CANCELED_SUBSCRIPTIONS',
  action: Action.GET_MANY
};
export const SUBSCRIPTIONS_PRICE_GET = {
  entity: 'SUBSCRIPTIONS_PRICE',
  action: Action.GET
};
export const SUBSCRIPTIONS_CANCEL = {
  entity: 'SUBSCRIPTIONS',
  action: Action.ARCHIVE
};
export const SESSION_SUBSCRIPTION_USER_PRESENCE = {
  entity: 'SESSION_SUBSCRIPTION_USER_PRESENCE',
  action: Action.UPDATE
};
export const CANCEL_CLASS_SUBS_FOR_USER = {
  entity: 'CANCELED_SUBSCRIPTIONS',
  action: Action.ARCHIVE
};
export const GET_CLASS_SUBS_FOR_USER = {
  entity: 'GET_CLASS_SUBS_FOR_USER',
  action: Action.GET_MANY
};
export const CHECK_SESSIONS_FOR_SUBSCRIPTIONS_N = {
  entity: 'CHECK_SESSIONS_FOR_SUBSCRIPTIONS_N',
  action: Action.HANDLE
};
export const CREATE_SESSIONS_SUBSCRIPTION_N = {
  entity: 'CREATE_SESSIONS_SUBSCRIPTION_N',
  action: Action.CREATE
};
export const VALIDATE_MULTIPLE_SESSIONS_MEMBERSHIP_RULES = {
  entity: 'VALIDATE_MULTIPLE_SESSIONS_MEMBERSHIP_RULES',
  action: Action.GET_MANY
};
export const GET_SUBSCRIPTIONS_SESSIONS_FOR_MULTIPLE_CANCEL = {
  entity: 'GET_SUBSCRIPTIONS_SESSIONS_FOR_MULTIPLE_CANCEL',
  action: Action.GET_MANY
};
export const SUBSCRIPTIONS_SESSIONS_MULTIPLE_CANCEL = {
  entity: 'SUBSCRIPTIONS_SESSIONS_MULTIPLE_CANCEL',
  action: Action.UPDATE
};
export const createSubscription = data => async dispatch => {
  const customerId = +data.customerId || null;
  const customerWalkInId = +data.customerWalkInId || null;
  const email = data.email || null;
  const {
    useMembership,
    pastSession,
    orderEvent,
    price
  } = data;
  const p = Array.isArray(data.sessions) ? null : +price;
  execute(dispatch, () => createSubscriptionApiCall({
    request: {
      email: data.customerSide ? null : email,
      customerId,
      customerWalkInId,
      classId: +data.classId,
      isPaid: parseAsBool(data.isPaid),
      price: p || null,
      sessions: Array.isArray(data.sessions) ? data.sessions.map(x => +x) : [+data.sessions],
      useMembership,
      status: data.status,
      pastSession: pastSession || false,
      orderEvent
    }
  }), SUBSCRIPTIONS_CREATE);
};
export const createSubscriptionOnPastEvent = data => async dispatch => {
  const customerId = +data.customerId || null;
  const email = data.email || (customerId ? null : data.customerId);
  const {
    useMembership
  } = data;
  execute(dispatch, () => createSubscriptionOnPastEventApiCall({
    request: {
      email,
      customerId,
      classId: +data.classId,
      isPaid: parseAsBool(data.isPaid),
      sessions: Array.isArray(data.sessions) ? data.sessions.map(x => +x) : [+data.sessions],
      useMembership
    }
  }), SUBSCRIPTIONS_CREATE);
};
export const createSubscriptionAsAnonymous = _ref => {
  let {
    email,
    firstname,
    lastname,
    phone,
    ...rest
  } = _ref;
  return async dispatch => {
    const request = {
      classId: +rest.classId,
      sessions: rest.sessions.map(x => +x),
      onlyBook: parseAsBool(rest.onlyBook),
      email,
      firstname: firstname || null,
      lastname: lastname || null,
      phone: phone || null
    };
    execute(dispatch, () => createSubscriptionAsAnonymousApiCall(request), SUBSCRIPTIONS_CREATE, true);
  };
};
export const createSubscriptionReset = () => async dispatch => dispatch({
  type: buildActionType(SUBSCRIPTIONS_CREATE, ActionStatus.RESET)
});
export const getSubscriptionsBySessionId = request => async dispatch => execute(dispatch, () => getSubscriptionsBySessionIdApiRequst(request), SUBSCRIPTIONS_GET_MANY);
export const getCanceledSubscriptionsBySessionId = request => async dispatch => execute(dispatch, () => getCanceledSubscriptionsBySessionApiRequest(request), CANCELED_SUBSCRIPTIONS_GET_MANY);
export const cancelSubscription = subscriptionId => async dispatch => execute(dispatch, () => cancelSubscriptionApiRequest(subscriptionId), SUBSCRIPTIONS_CANCEL);
export const cancelSubscriptionSession = (subscriptionId, sessionId) => async dispatch => execute(dispatch, () => cancelSubscriptionSessionApiRequest(subscriptionId, sessionId), SUBSCRIPTIONS_CANCEL);
export const cancelSessionWithAllSubscriptions = id => async dispatch => execute(dispatch, () => cancelSessionWithAllSubscriptionsApiCall({
  id
}), SUBSCRIPTIONS_CANCEL);
export const getSubscriptionPrice = _ref2 => {
  let {
    classId,
    sessions
  } = _ref2;
  return async dispatch => execute(dispatch, () => getSubscriptionPriceApiRequest({
    classId,
    sessions
  }), SUBSCRIPTIONS_PRICE_GET);
};
export const activateSubscription = id => async dispatch => execute(dispatch, () => activateSubscriptionApiCall({
  id: +id
}), SUBSCRIPTIONS_UPDATE);
export const changeUserSessionSubscriptionPresence = (sessionId, subscriptionId, presence) => async dispatch => execute(dispatch, () => changeUserSessionSubscriptionPresenceApiCall({
  sessionId,
  subscriptionId,
  presence
}), SESSION_SUBSCRIPTION_USER_PRESENCE);
export const cancelSubscriptionsOfUserInSpecificClass = (classId, userId, customerId) => async dispatch => execute(dispatch, () => cancelSubscriptionsOfUserInSpecificClassApiCall({
  classId,
  userId,
  customerId
}), CANCEL_CLASS_SUBS_FOR_USER);
export const getSubscriptionsOfUserInSpecificClass = (classId, userId, customerId) => async dispatch => execute(dispatch, () => getSubscriptionsOfUserInSpecificClassApiCall({
  classId,
  userId,
  customerId
}, {
  sessions: ['id', 'startTime'],
  user: ['id', 'name', 'email', 'firstname', 'lastname'],
  visitatore: ['id', 'name', 'email', 'firstname', 'lastname']
}), GET_CLASS_SUBS_FOR_USER);
export const checkSessionsForSubscriptionN = request => async dispatch => {
  execute(dispatch, () => checkSessionsForSubscriptionNApiCall({
    request
  }, {
    sessions: ['id', 'startTime', 'endTime', 'reserved', 'capacityStatus', 'weekDay', 'status', 'classId'],
    memberships: ['id', 'name']
  }), CHECK_SESSIONS_FOR_SUBSCRIPTIONS_N);
};
export const validateMultipleSessionsMembershipRules = data => async dispatch => {
  const userId = +data.userId || null;
  const customerId = +data.customerId || null;
  const classId = +data.classId || null;
  const sessions = Array.isArray(data.sessions) ? data.sessions.map(x => +x) : [+data.sessions];
  execute(dispatch, () => validateMultipleSessionsMembershipRulesApiCall({
    sessions,
    classId,
    userId,
    customerId
  }), VALIDATE_MULTIPLE_SESSIONS_MEMBERSHIP_RULES);
};
export const createSessionsForSubscriptionN = request => async dispatch => execute(dispatch, () => createSessionsForSubscriptionNApiCall({
  request
}), CREATE_SESSIONS_SUBSCRIPTION_N);
export const getSubscriptionsSessionsForMultipleCancel = data => async dispatch => {
  const request = {
    serviceId: +data.courseId,
    classId: +data.classId || null,
    fromDate: "".concat(data.fromDate, "T00:00:00"),
    toDate: "".concat(data.toDate, "T23:59:00"),
    userId: +data.userId || null,
    customerId: +data.customerId || null,
    resourceId: +data.resourcesId || null,
    inactiveOnly: data.inactiveOnly === 'true' ? true : false
  };
  execute(dispatch, () => getSubscriptionsSessionsForMultipleCancelApiCall({
    request
  }), GET_SUBSCRIPTIONS_SESSIONS_FOR_MULTIPLE_CANCEL);
};
export const cancelMultipleSubscriptionsSessions = (subscriptionSessionsIds, inactiveOnly, cancelSession) => async dispatch => {
  const boolInactiveOnly = inactiveOnly === 'true' ? true : false;
  const boolCancelSession = cancelSession === 'true' ? true : false;
  execute(dispatch, () => cancelMultipleSubscriptionsSessionsByIdsApiCall({
    subscriptionSessionsIds,
    inactiveOnly: boolInactiveOnly,
    cancelSession: boolCancelSession
  }), SUBSCRIPTIONS_SESSIONS_MULTIPLE_CANCEL);
};

// Reset actions
export const resetCancelSubState = () => async dispatch => dispatch(resetState(SUBSCRIPTIONS_CANCEL));
export const resetCreateSubscriptionState = () => async dispatch => dispatch(resetState(SUBSCRIPTIONS_CREATE));

//Reducers
export const subscriptionReducers = {
  active: basicReducer(SUBSCRIPTIONS_GET_MANY),
  cancel: basicReducer(SUBSCRIPTIONS_CANCEL),
  create: basicReducer(SUBSCRIPTIONS_CREATE),
  price: basicReducer(SUBSCRIPTIONS_PRICE_GET),
  update: basicReducer(SUBSCRIPTIONS_UPDATE),
  canceled: basicReducer(CANCELED_SUBSCRIPTIONS_GET_MANY),
  userPresence: basicReducer(SESSION_SUBSCRIPTION_USER_PRESENCE),
  userSubscriptionsOfClass: basicReducer(GET_CLASS_SUBS_FOR_USER),
  cancelUserSubscriptionOfClass: basicReducer(CANCEL_CLASS_SUBS_FOR_USER),
  checkSessionsSubscriptionN: basicReducer(CHECK_SESSIONS_FOR_SUBSCRIPTIONS_N),
  createSessionsSubscriptionN: basicReducer(CREATE_SESSIONS_SUBSCRIPTION_N),
  validateMultipleSessionsMR: basicReducer(VALIDATE_MULTIPLE_SESSIONS_MEMBERSHIP_RULES),
  subscriptionsSessionsForCancel: basicReducer(GET_SUBSCRIPTIONS_SESSIONS_FOR_MULTIPLE_CANCEL),
  subscriptionsSessionsMultiCancel: basicReducer(SUBSCRIPTIONS_SESSIONS_MULTIPLE_CANCEL)
};
export const subscriptionFeelReducers = {
  create: basicReducer(SUBSCRIPTIONS_CREATE)
};