import _taggedTemplateLiteral from "/codebuild/output/src2268684349/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import { API } from "aws-amplify";
import gql from "graphql-tag";
export const ErrorPrefix = {
  Authentication: 'AUTH',
  Authorization: 'AZ',
  Custom: 'CUS',
  InputValidation: 'IV',
  InputValidationInvalidFormat: 'IV.IFMT',
  InputValidationRequired: 'IV.RQ',
  InputValidationSmallerThan: 'IV.ST',
  InputValidationGreaterThan: 'IV.GT',
  NotSupportedAppVersion: 'NSAV',
  System: 'SYS'
};
export const apiCall = async _ref => {
  let {
    query,
    variables,
    fragments
  } = _ref;
  let fragmentStr = '';
  if (fragments) {
    fragmentStr = Object.keys(fragments).reduce((agg, val) => {
      agg += val + ' {\n' + fragments[val].join('\n') + '\n}';
      return agg;
    }, '');
  }
  try {
    const response = await API.graphql({
      query: gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["", ""])), query.replace('...fragments', fragmentStr)),
      variables
    });
    return {
      success: true,
      data: Object.values(response.data)[0]
    };
  } catch (e) {
    if (!e.errors) {
      return {
        success: false,
        message: 'Unknown error'
      };
    }
    const errors = e.errors.map(_ref2 => {
      let {
        extensions,
        message
      } = _ref2;
      return {
        message,
        inputs: extensions === null || extensions === void 0 ? void 0 : extensions.inputs,
        affected: extensions === null || extensions === void 0 ? void 0 : extensions.affected,
        reason: extensions === null || extensions === void 0 ? void 0 : extensions.reason
      };
    });
    return {
      success: false,
      errors
    };
  }
};